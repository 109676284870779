import axios, { AxiosResponse } from 'axios';
import { BLUEY_SMOKE_URL, BOMBAY_CURRY_PIZZA_URL } from 'src/utils/urls';
const ApiBaseUrl = process.env.REACT_APP_API_URL || '';

const bombay_curry_pizza_headers = {
  tenantcode: 'bombay-curry-pizza',
  clientapikey: '6521e02e89ea8c5586836f33_webAdminbombaycurry',
  clientapisecrete:
    'snixGepz6JRShIyGg1KDZea1xP3bCuzplcbRHsUxBpED83RNnDBDWbombaycurry',
};
const blue_smoke_headers = {
  tenantcode: 'blue-smoke',
  clientapikey: 'virtual-terminal-1blue',
  clientapisecrete:
    '8468fIyGg1KDZea1xP343BpED83RNnG1GAblue',
};
const buono_pizza_headers = {
  tenantcode: 'buono-pizza',
  clientapikey: '6521e02e89ea8c5586836f31_pos',
  clientapisecrete:
    's2bs10s3jbEdg6IxtfKzlWmxWrXSuHrOrPvpNEbAwGVHd9AX085BpI0nZggy',
};

export const callApi = async (
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  url: string,
  onAceesFail: (error?: any) => void,
  onAceesSuccess: (response: AxiosResponse) => void,
  values?: any,
): Promise<void> => {
  let formData: any = {
    method: method,
    headers: bombay_curry_pizza_headers,
    // headers: ApiBaseUrl === BOMBAY_CURRY_PIZZA_URL ? bombay_curry_pizza_headers : ApiBaseUrl === BLUEY_SMOKE_URL ? blue_smoke_headers : buono_pizza_headers,
  };

  if (values !== undefined && values !== '') {
    formData['data'] = values;
  }
  console.log(url, formData);


  try {
    switch (method) {
      case 'GET':
        const getResponse = await axios.get(url, formData);

        if (getResponse.status === 200) {
          onAceesSuccess(getResponse);
        } else {
          onAceesFail(getResponse);
        }
        break;

      case 'POST':
        const postResponse = await axios.post(url, values, formData);

        if (postResponse.status === 200 || postResponse.status === 201) {
          onAceesSuccess(postResponse);
        } else {
          onAceesFail(postResponse);
        }
        break;

      case 'DELETE':
        const deleteResponse = await axios.post(url, values, formData);
        if (deleteResponse.status === 200) {
          onAceesSuccess(deleteResponse);
        } else {
          onAceesFail();
        }
        break;

      case 'PUT':
        const updateResponse = await axios.put(url, formData);
        if (updateResponse.status === 200) {
          onAceesSuccess(updateResponse);
        } else {
          onAceesFail(updateResponse);
        }
        break;

      default:
        break;
    }
  } catch (error) {
    if (error) {
      onAceesFail(error);
    }
    throw error;
  }
};
