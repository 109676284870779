import {
  TextField,
  Autocomplete,
  Box,
  Card,
  Grid,
  styled,
  Button,
  Divider,
  Typography,
  FormControlLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import SaveIcon from '@mui/icons-material/Save';
import { Field, FieldProps} from 'formik';
import { TextField as FmTextFiled,Switch } from 'formik-mui';
import { useState } from 'react';
import EditorWrapper from 'src/components/StyledWrappers/EditorWrapper';


function GeneralSection() {
  const { t }: { t: any } = useTranslation();
    const [toggle, setToggle] = useState(false);
    const handleToggle = () => {
      //  toggle ? setToggle(false) : setToggle(true);
    };
  return (
    <Card
      sx={{
        p: 3
      }}
    >
      <Grid container spacing={3}>
              <Grid item xs={12}>
                  <Field
                      fullWidth
                      name="name"
                      component={FmTextFiled}
                      label="Item name *"
                      placeholder={t('Item name here...')}
                  />
              </Grid>
              <Grid item xs={12}>
                  <Field
                      fullWidth
                      name="code"
                      component={FmTextFiled}
                      label="Item code"
                      variant="outlined"
                  />
              </Grid>
             
              <Grid item xs={12}>
                  <Field
                      name="description">
                      {({ field, form, meta }: FieldProps) => (
                          <EditorWrapper >
                              <ReactQuill placeholder="Item Description" value={field.value}
                                  onChange={(o) => form.setFieldValue(field.name,o)} />
                          </EditorWrapper>
                      )}
                  </Field>
                 
        </Grid>
             
              
          </Grid>
          

    </Card>
  );
}

export default GeneralSection;
